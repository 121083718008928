
<template>
  <comprehensive>
    <div class="container_top">
      <div class="container_topDiv">
        <div class="copDiv">
          <div>
            {{ this.$route.query.city }} {{ this.$route.query.product_name }}
            {{ this.$route.query.material }}
            {{ this.$route.query.specifications }}
            {{ this.$route.query.origin_place }}
          </div>
          <div style="display: flex">
            <div style="margin-right: 80px; width: 120px; text-align: right">
              {{ this.$route.query.price }}
            </div>
            <div style="width: 120px;">
              <span style="display:flex;align-items: center;justify-content: center;"  v-if="this.$route.query.home_diff_price > 0">
                 <img  src="../views/home/img/plus2.svg"  class="icon"/>  {{ this.$route.query.home_diff_price }}
              </span>
              <span style="display:flex;align-items: center;justify-content: center;"  v-if="this.$route.query.home_diff_price < 0">
                 <img  src="../views/home/img/reduce2.svg"  class="icon"/>  {{ Math.abs(this.$route.query.home_diff_price)  }}
              </span>
              <span style="display:flex;align-items: center;justify-content: center;"  v-if="this.$route.query.home_diff_price == 0">
                持平
              </span>
            </div>
          </div>
        </div>
        <div class="copDiv" style="margin-top: 20px; font-size: 18px">
          <div style="font-size:24px">{{ this.$route.query.date || "" }}</div>
          <div style="display: flex;">
            <div style="margin-right: 80px; width: 120px; text-align: right">
              最新价格
            </div>
            <div style="width: 120px; text-align: center">较上日</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_div" style="padding-bottom: 30px">
      <div class="container_divTitle">
        <img
          src="../views/home/img/title.png"
          style="width: 3px; height: 18px; margin-right: 5px"
        />
        日走势
      </div>
      <div class="container_divView" style="padding-top: 24px">
        <div class="echartViewTop">
          <div
            :class="
              dayActive == 1 ? 'echartTopBtn echartTopBtnRed' : 'echartTopBtn'
            "
            @click="tabDay(1)"
          >
            一个月
          </div>
          <div
            :class="
              dayActive == 2 ? 'echartTopBtn echartTopBtnRed' : 'echartTopBtn'
            "
            @click="tabDay(2)"
          >
            三个月
          </div>
          <div
            :class="
              dayActive == 3 ? 'echartTopBtn echartTopBtnRed' : 'echartTopBtn'
            "
            @click="tabDay(3)"
          >
            六个月
          </div>
          <div
            :class="
              dayActive == 4 ? 'echartTopBtn echartTopBtnRed' : 'echartTopBtn'
            "
            @click="tabDay(4)"
          >
            近一年
          </div>
        </div>
        <div class="echartView" id="day" v-loading="dayLoading"></div>
      </div>
      <div class="container_divTitle">
        <img
          src="../views/home/img/title.png"
          style="width: 3px; height: 18px; margin-right: 5px"
        />
        今日城市基价对比
      </div>
      <div class="container_divView" style="padding-top: 24px">
        <div class="echartView" id="city" v-loading="cityLoading"></div>
      </div>
      <div class="container_divTitle">
        <img
          src="../views/home/img/title.png"
          style="width: 3px; height: 18px; margin-right: 5px"
        />
        加减价规则
        <div @click="setJactive(1)"
          :class="jjActive == 1 ? 'titleVIew titleVIew_active' : 'titleVIew'"
          style="margin-left: 11px; border-right: none"
        >
          仓库加价
        </div>
        <div @click="setJactive(2)"
          :class="jjActive == 2 ? 'titleVIew titleVIew_active' : 'titleVIew'"
          style="border-right: none"
        >
          材质加价
        </div>
        <div @click="setJactive(3)"
          :class="jjActive == 3 ? 'titleVIew titleVIew_active' : 'titleVIew'"
        >
          规格加价
        </div>
      </div>
      <div
        class="container_divView"
        style="padding-top: 30px;text-align: center"
        v-loading="priceLoading"
      >
        <div class="container_divTable">
          <div class="tableTR" style="background-color: #f2f2f2">
            <div
              class="table_title_name"
              style="font-weight: 700; padding-left: 265px"
            >
              <span v-if="jjActive == 1">仓库</span>
              <span v-if="jjActive == 2">材质</span>
              <span v-if="jjActive == 3">规格</span>
            </div>
            <div
              style="text-align: right; font-weight: 700; padding-right: 378px"
            >
              加价
            </div>
          </div>
          <div class="tableTR" v-for="(item, index) in pirceList.data" :key="index">
            <div style="padding-left: 265px">{{ item.addPriceProjName }}</div>
            <div style="text-align: right; padding-right: 378px">
              {{ item.addPrice }}
            </div>
          </div>
          <div v-if="pirceList.data.length == 0" style="text-align: center;font-size:14px;margin:10px auto">
            暂无数据
          </div>
        </div>
        <div v-if="pirceList.total > 10" style="margin-top:20px;">
          <el-pagination
               background
               layout="total,prev, pager, next,jumper"
               :total="pirceList.total"
               :page-size="num"
               @current-change="gotoNext"
               :current-page="pirceList.pages"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </comprehensive>
</template>

<script>
import echarts from "echarts";
import { mapState, mapActions } from "vuex";
import comprehensive from "./comprehensive.vue";
import dayjs from 'dayjs'
export default {
  components: {
    comprehensive,
  },
  name: "info",
  data() {
    return {
      pages:1,
      num: 10,
      visable: false,
      dayDate: [],
      Day_advise_price: [],
      data: {},
      dayActive: 1,
      cityDate: [],
      cityPrice: [],
      pirceList: {},
      jjActive:1,
    };
  },
  methods: {
    ...mapActions("ddmg/home", [
      "getDay",
      "getCity",
      "priceTrendsMarkUpList",
    ]),
    //翻页
    gotoNext(val) {
        //分页
        if (val < 1 || val > this.pirceList.totalPage) {
            this.$message.error("当前没有更多页面了");
            return;
        }
        this.pages = val;
        this.getPriceTrendsMarkUpList(this.jjActive);
        //点击翻页 输入传入筛选条件，目前还没有字段
    },
    tabDay(index) {
      this.dayActive = index;
      let startDate = ''
      if (index == 1) {
          startDate = dayjs().subtract(30, 'day').format("YYYY-MM-DD");
      }
      if (index == 2) {
          startDate = dayjs().subtract(90, 'day').format("YYYY-MM-DD");
      }
      if (index == 3) {
          startDate = dayjs().subtract(180, 'day').format("YYYY-MM-DD");
      }
      if (index == 4) {
          startDate = dayjs().subtract(365, 'day').format("YYYY-MM-DD");
      }
      let body = {
          product: this.$route.query.product_name,
          origin: this.$route.query.origin_place,
          cityName: this.$route.query.city,
          categoryName: this.$route.query.categoryName,
          startDate:startDate,
          endDate: dayjs().format("YYYY-MM-DD")
      };
        this.getDay(body).then((res) => {
              this.data = res;
              this.dayDate = [];
              this.Day_advise_price = [];
              res.forEach((item) => {
                  this.dayDate.push(item.dateStr);
                  this.Day_advise_price.push(item.thisDayPrice);
              });
            this.echartsData();
        });


    },
    echartsData() {
      let myChart = echarts.init(document.getElementById("day"));
      let myChart2 = echarts.init(document.getElementById("city"));
      var option1 = {
        title: {
          textStyle: {
            fontSize: 14,
            color: "#666666",
            fontWeight: "400",
          },
          left: "30",
          top: "20",
          text: "",
          x: "10px", //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          y: "10px", //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "white",
          borderColor: "#DDDDDD", // 提示框浮层的边框颜色。
          borderWidth: 1, // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#666666",
            fontSize: 14,
          },
          extraCssText: "box-shadow:0px 0px 5px rgba(0, 0, 0, 0.19)", // 悬浮框阴影
          formatter: function (val) {
            let i =
              '<span style="border-radius:10px;display:inline-block;margin-right:4px;width:6px;height:6px;background-color:white;border:3px solid #fd6b30"></span>';
            return (
              i +
              val[0].seriesName +
              " " +
              val[0].value +
              "元" +
              "<br>" +
              i +
              "日期" +
              " " +
              val[0].axisValue
            );
            // do some thing
          },
        },
        legend: {
          // orient: 'vertical',
          // icon:'pin',
          itemWidth: 12,
          itemHeight: 12,
          x: "right",
          y: "20px",
          data: [],
        },
        toolbox: {},
        grid: {
          top: 25,
          left: "6%",
          right: "6%",
          bottom: "6%",
          containLabel: true,
        },
        xAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#666666",
              fontSize: 14,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          type: "category",
          boundaryGap: true,
          data: this.dayDate,
        },
        yAxis: {
          type: "value", //坐标轴类型
          positionL: "left",
          scale: true,

          //y轴
          axisLabel: {
            show: true,
            textStyle: {
              color: "#666666",
              fontSize: 14,
            },
            interval: "auto",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#DDDDDD",
              fontSize: 14,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },

        series: [
          {
            name: "基价",
            type: "line",
            data: this.Day_advise_price,
            itemStyle: {
              normal: {
                color: "#fd6b30", //图标颜色
              },
            },
            lineStyle: {
              normal: {
                color: "#e49446", //连线颜色
              },
            },
            areaStyle: {
              color: "rgba(255, 235, 228, 1)",
            },
          },
        ],
      };
      var option2 = {
        title: {
          textStyle: {
            fontSize: 14,
            color: "#666666",
            fontWeight: "400",
          },
          left: "30",
          top: "20",
          text: "",
          x: "10px", //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          y: "10px", //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "white",
          borderColor: "#DDDDDD", // 提示框浮层的边框颜色。
          borderWidth: 1, // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#666666",
            fontSize: 14,
          },
          extraCssText: "box-shadow:0px 0px 5px rgba(0, 0, 0, 0.19)", // 悬浮框阴影
          formatter: function (val) {
            let i =
              '<span style="border-radius:10px;display:inline-block;margin-right:4px;width:10px;height:10px;background-color:white;border:3px solid #fd6b30"></span>';
            return (
              i +
              val[0].seriesName +
              " " +
              val[0].value +
              "元" +
              "<br>" +
              i +
              "城市" +
              " " +
              val[0].axisValue
            );
            // do some thing
          },
        },
        legend: {
          // orient: 'vertical',
          // icon:'pin',
          itemWidth: 12,
          itemHeight: 12,
          x: "right",
          y: "20px",
          data: [],
        },
        toolbox: {},
        grid: {
          top: 25,
          left: "6%",
          right: "6%",
          bottom: "6%",
          containLabel: true,
        },
        xAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#666666",
              fontSize: 14,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#DDDDDD",
            },
          },
          type: "category",
          boundaryGap: true,
          data: this.cityDate,
        },
        yAxis: {
          type: "value", //坐标轴类型
          positionL: "left",
          scale: true,

          //y轴
          axisLabel: {
            show: true,
            textStyle: {
              color: "#666666",
              fontSize: 14,
            },
            interval: "auto",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#DDDDDD",
              fontSize: 14,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },

        series: [
          {
            name: "基价",
            type: "bar",
				barWidth:17,
            data: this.cityPrice,
            itemStyle: {
              normal: {
                color: "#fd6b30", //图标颜色
              },
            },
            lineStyle: {
              normal: {
                color: "#e49446", //连线颜色
              },
            },
            areaStyle: {
              color: "rgba(255, 235, 228, 1)",
            },
          },
        ],
      };
      option1 && myChart.setOption(option1);
      option2 && myChart2.setOption(option2);
    },
    init() {
      let body = {
        product: this.$route.query.product_name,
        origin: this.$route.query.origin_place,
        cityName: this.$route.query.city,
        categoryName: this.$route.query.categoryName,
        startDate:dayjs().subtract(30, 'day').format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD")
      };
      this.getDay(body).then((res) => {
        this.data = res;
        this.dayDate = [];
        this.Day_advise_price = [];
        res.forEach((item) => {
          this.dayDate.push(item.dateStr);
          this.Day_advise_price.push(item.thisDayPrice);
        });
        let body2 = {
            product: this.$route.query.product_name,
            origin: this.$route.query.origin_place,
            categoryName: this.$route.query.categoryName,
            isNew:1,
        };
        this.getCity(body2).then((xres) => {
          this.cityDate = [];
          this.cityPrice = [];
          xres.forEach((item) => {
            this.cityDate.push(item.cityName);
            this.cityPrice.push(item.thisDayPrice);
          });

          this.echartsData();
        });
      });
      this.getPriceTrendsMarkUpList(1);
    },
    getPriceTrendsMarkUpList(type) {
      let body = {
        pages: this.pages,
        rows: 10,
        addPriceTypeCode: type,
        product: this.$route.query.product_name,
        origin: this.$route.query.origin_place,
        cityName: this.$route.query.city,
      };
      this.priceTrendsMarkUpList(body).then((res) => {
        this.pirceList = res;
      });
    },
      setJactive(index){
          this.jjActive= index;
          this.getPriceTrendsMarkUpList(index)
      },
  },
  computed: {
    ...mapState("ddmg/home", {
      dayLoading: (state) => state.dayLoading,
      cityLoading: (state) => state.cityLoading,
      priceLoading: (state) => state.priceLoading,
    }),
  },
  mounted() {
    this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container_divTable .tableTR:nth-child(odd) {
  background-color: #fcfcfc;
}
.container_divTable .tableTR {
  width: 1022px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_divTable .tableTR div {
  color: #333333;
  font-size: 16px;
  width: 511px;
  height: 42px;
  line-height: 42px;
}
.container_divView {
  border-radius: 26px;
  width: 1122px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 16%);
  margin: 0px auto;
  margin-top: 20px;
  padding-bottom: 50px;
}
.container_divTable {
  width: 1022px;
  border: 1px solid;
  border-color: #f2f2f2;
  border-radius: 16px;
  margin: 0 auto;
}
.titleVIew {
  width: 72px;
  height: 28px;
  border: 1px solid;
  border-color: #e8e8e8;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.titleVIew_active {
  color: #ef2147;
}
.container_divView {
  border-radius: 26px;
  width: 1122px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 16%);
  margin: 0px auto;
  margin-top: 20px;
  padding-bottom: 50px;
}
.echartView {
  width: 1000px;
  height: 500px;
  margin: 0 auto;
}
.echartViewTop {
  width: 550px;
  height: 35px;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.echartTopBtn {
  width: 88px;
  height: 33px;
  line-height: 33px;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #999999;
  font-size: 14px;
  text-align: center;
  margin: 0px 33px;
  cursor: pointer;
}
.echartTopBtnRed {
  background-color: #fee8ec;
  color: #ef2147;
}
.container_div {
  width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  position: relative;
  top: -49px;
}
.container_divTitle {
  padding-left: 30px;
  font-weight: 700;
  color: #333333;
  font-size: 20px;
  padding-top: 24px;
  display: flex;
  align-items: center;
}
.copDiv {
  display: flex;
  justify-content: space-between;
}
.icon {
  margin: 0px 6px;
}
img {
  vertical-align: middle;
}
.container_top {
  height: 201px;
  background: url(../views/home/img/priceGuide.png) no-repeat center;
  display: flex;
  background-size: cover;
}
.container_topDiv {
  width: 915px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-size: 24px;
  margin: 20px auto;
  padding-left: 135px;
  padding-right: 150px;
}
</style>
